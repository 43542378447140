// extracted by mini-css-extract-plugin
export var articleList = "Calculator-article-list--SZJD0";
export var headingH3 = "Calculator-heading--h3--+mc9+";
export var listCategory = "Calculator-list-category--uwTCA";
export var listCircles = "Calculator-list-circles--cRHuh";
export var listInline = "Calculator-list-inline--n9tLk";
export var listPlain = "Calculator-list-plain--l7GX9";
export var listSmColumn = "Calculator-list-sm-column--v9HaU";
export var listTag = "Calculator-list-tag--CoJ4v";
export var purchaseAmount = "Calculator-purchaseAmount--jigtX";
export var red = "Calculator-red--SYDZ3";
export var sectionHeadline = "Calculator-section-headline--x58od";
export var spacingVBLg = "Calculator-spacing-v-b-lg--5kwgS";
export var spacingVBMd = "Calculator-spacing-v-b-md--R9Aag";
export var spacingVBSm = "Calculator-spacing-v-b-sm--G8M0g";
export var spacingVBZ = "Calculator-spacing-v-b-z--Ls8rC";
export var spacingVTLg = "Calculator-spacing-v-t-lg--rjcaU";
export var spacingVTMd = "Calculator-spacing-v-t-md--72n3W";
export var spacingVTSm = "Calculator-spacing-v-t-sm--3NoRx";
export var spacingVTZ = "Calculator-spacing-v-t-z--Ni5ql";
export var textLink = "Calculator-textLink--4nz74";
export var uFlex = "Calculator-u-flex--3eCTT";
export var uLg1of4 = "Calculator-u-lg-1of4--Ab4Pu";
export var uLg3of4 = "Calculator-u-lg-3of4--e1gr6";
export var wrapper = "Calculator-wrapper--HGH+w";